import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'
import SelectSearch from 'react-select-search'
import PhoneInput from 'react-phone-input-2'

import { SIZES } from '../../styles/theme'

import BgImg from '../../images/registration/form-background.jpg'
import shapes from '../../images/registration/Shapes.png'
import info from '../../images/icons/infoicon.png'
import countryList from '../../constants/countryList'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(203, 10, 62, 1),
      rgba(203, 10, 62, 1)
    ),
    url(${BgImg});
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #FCFCFC;
  padding: 2.5em;
  line-height: 1em;

  ${SIZES.mobile} {
    background-position: center top;
    padding: 2.5em 0;
    font-size: 1.1em;
  }

  .row {
    background-image: url(${shapes});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    header {
      text-align: center;
      font-style: italic;
      h3 {
        font-weight: 600;
      }
    }
    .register-form {
      color: #840024;
      p.message.text-white {
        width: 50%;
        ${SIZES.mobile} {
          width: 75%;
        }
      }
      .form-group {
        margin: 12px auto;
        width: 50%;
        border: 1px solid #CB0A3E;
        background: #F8F4FF;
        text-align: center;
        border-radius: 30px;
        ${SIZES.mobile} {
          width: 75%;
        }
        label {
          margin: 0 auto;
          font-weight: 500;
          font-style: italic;
          padding: 4px auto;
          padding-bottom: 0;
          font-size: 12px;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #2F2651;
          letter-spacing: 1px;
          width: 100%;
          background: transparent;
          border: none;
          padding: 4px 24px;
          outline: none;
          font-size: 12px;
        }
        .react-tel-input {
          color: #2f2651;
          input[type='tel'] {
            padding-left: 56px;
            height: 25px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
    }
  }

  a,
  button.submit-btn {
    background: #CB0A3E;
    width: 50%;
    margin: 0 auto;
    color: #F6F6F9;
    font-weight: 500;
    font-style: italic;
    border: solid 2px white;
    border-radius: 30px;
    display: flex;
    padding: 4px 24px;
    justify-content: space-between;
    font-size: 0.8em;
    outline: none;
    box-shadow: none;
    ${SIZES.mobile} {
      width: 75%;
    }
    &:disabled {
      cursor: not-allowed;
    }
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
  /* searchable select input */
  .select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    font-size: 12px;
  }
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  .select-search__value {
    position: relative;
    z-index: 1;
  }
  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }
  .select-search__input {
    color: #2f2651;
    background: transparent;
    border: none;
    padding: 4px 24px;
    border-radius: 25px;
    display: block;
    width: 100%;
    outline: none;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    cursor: pointer;
  }
  .select-search__input:focus {
    cursor: initial;
  }
  .select-search__options {
    list-style: none;
    padding-right: 8px;
    padding-left: 8px;
  }
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }
  .select-search__option,
  .select-search__not-found {
    display: block;
    min-height: 32px;
    width: 100%;
    padding: 0 8px;
    background: #F8F4FF;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }
  .select-search__option.is-selected {
    background: #CB0A3E;
    color: #fff;
  }
  .select-search .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: #eb9bb0;
  }
  .select-search .select-search__select {
    background: #F8F4FF;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 300px;
  }
  .select-search__not-found {
    height: auto;
    padding: 16px;
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [salutation, setSalutation] = useState('Mr')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('Malaysia')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('60')
  const [company, setCompany] = useState('')
  const [designation, setDesignation] = useState('')
  const optionList = [
    'Mr',
    'Ms',
    'Mrs',
    'Miss',
    'Tun',
    'Tan Sri',
    'Datuk',
    'Dato Sri',
    'Datuk Seri',
    "Dato'",
    'Pehin',
    'JP',
  ]

  useEffect(() => {
    ReactGA.pageview('/register-form-page')
  }, [])

  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const options = {
      salutation,
      full_name: fullName.trim(),
      email,
      country,
      password: password.trim(),
      phone,
      company_name: company,
      designation: designation.trim(),
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    salutation.length &&
    fullName.length &&
    email.length &&
    country.length &&
    password.length &&
    phone.length >= 7 &&
    company.length &&
    designation.length
  )

  return (
    <Page>
      <Row noGutters>
        <Col md={{ span: 6, offset:3 }}>
          <header className="">
            <h3>Welcome</h3>
            <p>To A New Era With AMD</p>
          </header>
          <Form onSubmit={handleSubmit}>
            <div className="register-form pt-2">
              <Form.Group>
                <label>Salutation</label>
                <select
                  className="form-input"
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  {optionList.map((item, i) => (
                    <option key={`option-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group>
                <label>Full Name (as per NRIC)</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Mobile No.</label>
                <PhoneInput
                  inputProps={{ required: true }}
                  className="form-input"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  placeholder=""
                />
              </Form.Group>
              <Form.Group>
                <label>Email</label>
                <input
                  className="form-input"
                  required
                  type="email"
                  value={email}
                  onChange={handleEmailInput}
                />
              </Form.Group>
              <Form.Group>
                <label>Country</label>
                <SelectSearch
                  name="country"
                  value={country}
                  onChange={(country) => setCountry(country)}
                  options={countryList}
                  search
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group>
                <label>Company Name</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Designation</label>
                <input
                  className="form-input"
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>
                  Create A Password
                  <img src={info} alt="info" width="13" className="mx-2" />
                </label>
                <input
                  className="form-input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div>
                {hasEmptyField && (
                  <p className="message text-white mx-auto text-right">
                    <em>
                      <small>Please fill up all fields</small>
                    </em>
                  </p>
                )}
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="default"
                  disabled={hasEmptyField || loading}
                  onSubmit={handleSubmit}
                >
                  <div>{loading ? 'Loading...' : 'Submit'}</div>
                  <div className="arrow">⇀</div>
                </Button>
              </div>
            </div>
          </Form>
        </Col>

      </Row>
    </Page>
  )
}
