import Logo from './images/logo.png'
import Auditorium from './images/backdrops/auditorium.jpg'
import Countdown from './images/backdrops/countdown.jpg'
import Entrance from './images/backdrops/entrance.jpg'
import Lobby from './images/backdrops/lobby.gif'
import LobbyMusic from './images/audio/lobby.mp3'
import { SIZES } from './styles/theme'
import routes from './routes'

// make sure no trailing slash
const dev = {
  apiUrl: 'http://localhost:3000',
}

// make sure no trailing slash
const prod = {
  apiUrl: 'https://amdgobeyond.herokuapp.com',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

// if there is no portrait asset, just use landscape asset as portrait property value
const assets = {
  lobbyMusic: LobbyMusic,
  countdown: Countdown,
  booth: null,
  logo: {
    landscape: Logo,
    portrait: Logo,
  },
  entrance: {
    landscape: Entrance,
    portrait: Entrance,
  },
  lobby: {
    landscape: Lobby,
    portrait: Lobby,
  },
  auditorium: {
    landscape: Auditorium,
    portrait: Auditorium,
  },
  exhibitionHall: {
    landscape: null,
    portrait: null,
  },
}

const layout = {
  auditoriumVid: `
    top: 14.3%;
    bottom: 24%;
    left: 23%;
    right: 22.3%;
    ${SIZES.mobile} {
      top: 29.8%;
      bottom: 44.6%;
      left: 10.1%;
      right: 12.5%;
    }
  `,
}

const lobbyEntries = [
  {
    id: 'singapore-promo',
    destination: 'spore',
    css: `
      top: 24.6%;
      left: 13.8%;
      right: 81%;
      bottom: 65.6%;
      ${SIZES.mobile} {
        top: 24.6%;
        left: 13.8%;
        right: 81%;
        bottom: 65.6%;
      }
    `,
  },
  {
    id: 'malaysia-promo',
    destination: 'msia',
    css: `
      top: 24.6%;
      left: 20.8%;
      right: 74%;
      bottom: 65.6%;
      ${SIZES.mobile} {
        top: 24.6%;
        left: 20.8%;
        right: 74%;
        bottom: 65.6%;
      }
    `,
  },
  {
    id: 'auditorium',
    // pinText: 'Enter Auditorium',
    // overlayText: '',
    destination: routes.auditorium,
    css: `
      top: 53.5%;
      left: 34.8%;
      right: 53%;
      bottom: 39.8%;
      ${SIZES.mobile} {
        top: 54.7%;
        left: 33.3%;
        right: 51.3%;
        bottom: 40.4%;
      }
    `,
  },
  {
    id: 'cloudflix',
    // pinText: 'Enter Video Library',
    destination: routes.cloudflix,
    css: `
      top: 53.5%;
      left: 58%;
      right: 29.4%;
      bottom: 39.9%;
      ${SIZES.mobile} {
        top: 53.9%;
        left: 57%;
        right: 27.9%;
        bottom: 41%;
      }
    `,
  },
]

export default {
  assets,
  layout,
  lobbyEntries,

  eventName: 'AMD Ryzen',
  googleAnalyticsID: 'UA-180732110-2',
  gsheetQuestionApi:
    'https://script.google.com/macros/s/AKfycbzWqe4JgaKmJ2ukTwG0V-vp5TBCg2VaPeTIhQTWjRDCaUogdzI/exec',

  ...config,
}
