import axios from 'axios'
import { toast } from 'react-toastify'
import config from '../config'

const LOGIN_REQUEST = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'

const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

const currentUserTypeCheck = () => {
  const storageUser = localStorage.getItem('current_user')
  let resultObj = null
  if (storageUser) {
    try {
      resultObj = JSON.parse(storageUser)
    } catch {
      localStorage.clear()
      console.log('Malformed localstorage user. Reset to null')
    }
  }
  return resultObj
}

const initialState = {
  isFetching: false,
  isAuthenticated: localStorage.getItem('jwt_token') ? true : false,
  currentUser: currentUserTypeCheck(),
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        currentUser: action.user,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        currentUser: null,
      }

    default:
      return state
  }
}

export function refreshUser() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/auto_login`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          localStorage.setItem('current_user', JSON.stringify(data.user))
          dispatch(receiveLogin(data.user, false))
        } else {
          dispatch(rejectLogin('Failed to refresh user'))
        }
      })
      .catch((err) => {
        dispatch(rejectLogin('Fail to refresh user (Rejected)'))
        console.error('Error: ', err)
      })
  }
}

export function loginUser(credentials) {
  return (dispatch) => {
    dispatch(requestLogin())

    return axios
      .post(`${config.apiUrl}/api/v1/login`, credentials)
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          localStorage.setItem('jwt_token', data.token)
          localStorage.setItem('current_user', JSON.stringify(data.user))
          dispatch(receiveLogin(data.user))
        } else {
          dispatch(rejectLogin(data.error))
        }
      })
      .catch((err) => {
        dispatch(rejectLogin(err.response.data.message))
        console.error('Error: ', err)
      })
  }
}

export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('current_user')
    dispatch(receiveLogout())
  }
}

export function registerUser({ phone, password, email }) {
  return (dispatch) => {
    dispatch(requestLogin())

    return axios
      .post(`${config.apiUrl}/api/v1/users`, { phone, email, password })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          localStorage.setItem('jwt_token', data.token)
          localStorage.setItem('current_user', data.user)
          dispatch(receiveLogin(data.user))
        } else {
          dispatch(rejectLogin(data.error))
        }
      })
      .catch((err) => console.error('Error: ', err))
  }
}

function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  }
}

function receiveLogin(user, withToast = true) {
  if (withToast) {
    toast(`Hello ${user.fullName}, welcome to ${config.eventName}!`)
  }
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

function rejectLogin(message) {
  toast(message)
  return {
    type: LOGIN_FAILURE,
  }
}

function receiveLogout() {
  toast('You are logged out!')
  return {
    type: LOGOUT_SUCCESS,
  }
}
