import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import ReactGA from 'react-ga'

import { SIZES } from '../../styles/theme'

import logo from '../../images/AMDlogo.png'
import BgImg from '../../images/registration/background.jpg'
import ryzen from '../../images/registration/ryzen-Image.png'
import rogLogo from '../../images/registration/partners/ROGlogo.png'
import asrockLogo from '../../images/registration/partners/ASRockLogo.png'
import aorusLogo from '../../images/registration/partners/AorusLogo.png'
import msiLogo from '../../images/registration/partners/MsiLogo.png'
import sapphireLogo from '../../images/registration/partners/sapphirewhiteLogo.png'
import powerColorLogo from '../../images/registration/partners/PowerColorLogo.png'

const Page = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(203, 10, 62, 0.75),
      rgba(203, 10, 62, 0.75)
    ),
    url(${BgImg});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 4%;
  color: #F8F4FF;
  text-align: center;
  padding: 2.5em;

  ${SIZES.mobile} {
    font-size: 0.8em;
    padding: 2em;
    background-position: top;
    background-size: 175%;
  }
  header {
    img {
      width: 180px;
      margin: 32px auto;
      ${SIZES.mobile} {
        width: 100px;
        margin-top: 0;
      }
    }
    .messages {
      margin: 4px;
      font-size: 1.1em;
      span {
        font-weight: 500;
      }
    }
  }
  .row {
    img {
      width: 75%;
    }
    h6 {
      font-weight:700;
    }
    .col-6 {
      align-self: center;
      img {
        margin: 8px auto;
        width: 75%;
      }
    }
  }
`

export default function PostRegisterPage() {
  useEffect(() => {
    ReactGA.pageview('/post-register-page')
  }, [])

  return (
    <Page>
      <header>
        <img src={logo} alt="AMD"/>
        <p className="messages pt-0 pt-md-5">
          <em>
            Thank you for signing up for the launching of AMD GoBeyond
          </em>
        </p>
        <p className="messages">
          <span>
            You will be receiving an email confirmation shortly
          </span>
        </p>
      </header>
      <Row className="mt-5" noGutters>
        <Col className="d-none d-md-block" md={4}>
          <img src={ryzen} alt="ryzen"/>       
        </Col>
        <Col md={4}>
          <h6>Partners</h6>
          <Row className="px-5 py-3" noGutters>
            <Col xs={6}>
              <img src={asrockLogo} alt="asrock"/>
            </Col>
            <Col xs={6}>
              <img src={rogLogo} className="w-50" alt="rog"/>
            </Col>
            <Col xs={6}>
              <img src={aorusLogo} alt="aorus"/>
            </Col>
            <Col xs={6}>
              <img src={msiLogo} alt="msi"/>
            </Col>
            <Col xs={6}>
              <img src={sapphireLogo} alt="sapphire"/>
            </Col>
            <Col xs={6}>
              <img src={powerColorLogo} alt="powercolor"/>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <img src={ryzen} alt="ryzen"/>       
        </Col>
      </Row>
    </Page>
  )
}
