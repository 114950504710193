import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'

import { SIZES } from '../../styles/theme';

import logo from '../../images/AMDlogo.png'
import BgImg from '../../images/registration/background.jpg'
import ryzen from '../../images/registration/phonemock.png'
import routes from '../../routes';

const Page = styled.div`

  min-height: 100vh;
  background-image: url(${BgImg});
  background-repeat: no-repeat;
  background-size: cover;
  color: #FCFCFC;

  ${SIZES.mobile} {
    font-size: 70%;
    background-size: auto;
    background-position: top;
    padding-bottom: 0;
  }

  .content {
    padding: 5em;
    padding-bottom: 0;

    .col-md-4 {
      text-align: center;
      font-style: italic;
      
      .title {
        font-size: 1.75em;
        font-weight: 600;
        margin-top: 32px;
      }
      p {
        font-size: 1em;
        margin-bottom: 40px;
      }
      img {
        display: block;
        margin: 0 auto;
      }
      ${SIZES.mobile} {
        .title {
          font-size: 2.2em;
        }
        p {
          font-size: 1.5em;
        }
      }
    }
  }

  a,
  button {
    background: #CB0A3E;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    font-size: 1.2em;
    color: #F6F6F9;
    font-weight: 500;
    padding: 15px 35px;
    border-radius: 56px;
    font-style: italic ;

    &:hover {
      color: #F6F6F9;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function PreRegisterPage() {

  useEffect(() => {
    ReactGA.pageview('/pre-register-page')
  }, [])

  return (
    <Page>
      <Row className="content" noGutters>
        <Col md={{ span: 4, offset: 4 }}>
          <img src={logo} width="180" alt="AMD"/>
          <h3 className="title">Welcome</h3>
          <p className="text-white">To A New Era With AMD</p>
          <img src={ryzen} width="250" alt="ryzen"/>
          <Button variant="default" as={Link} to={routes.register}>
            RSVP
            <div className="arrow">⇀</div>
          </Button>
        </Col>
      </Row>
    </Page>
  )
}