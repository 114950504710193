import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import cdBg from '../images/backdrops/countdown.jpg'
import logo from '../images/AMDlogo.png'


const Page = styled.div`
  background: #CB0A3E;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  #header {
    padding-top: 3vh;
    h1 {
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: .8em;
      font-size: 3rem;
    }
    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;
      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(242,241,241,1) 70%, rgba(180,179,179,1) 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;
        p {
          margin-bottom: 6px;
          font-weight: 500;
        }
        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }
  #bg {
    background-image: linear-gradient(
      rgba(203, 10, 62, 1),
      rgba(203, 10, 62, 1)
    ),
    url(${cdBg});
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 75%;
    min-height: calc(100vh - 224px);
    width: 100vw;
    text-align: center;
    color: #FFFFFF;
    padding: 3em;
  }
`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    const difference = +new Date(2020, 10, 19, 20, 0, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })

  return (
    <Page>
      <div id="header">
        <h1>COMING SOON</h1>
        <div className="time-wrapper">
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
      </div>
      <div id="bg">
        <img src={logo} width="175px" alt="AMD" />
        <div className="my-5">
          <h3>To A New Era With AMD</h3>
          <h4>19th, November 2020</h4>
          <h4>8:00PM</h4>
        </div>
      </div>
    </Page>
  )
}