import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import ReactGA from 'react-ga'
import SelectSearch from 'react-select-search'
import PhoneInput from 'react-phone-input-2'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'

import { SIZES } from '../../styles/theme'

import BgImg from '../../images/registration/form-background.jpg'
import shapes from '../../images/registration/Shapes.png'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(rgba(203, 10, 62, 1), rgba(203, 10, 62, 1)),
    url(${BgImg});
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fcfcfc;
  padding: 2.5em;
  line-height: 1em;

  ${SIZES.mobile} {
    background-position: center top;
    padding: 2.5em 0;
    font-size: 1.1em;
  }

  .row {
    background-image: url(${shapes});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    header {
      text-align: center;
      font-style: italic;
      h3 {
        font-weight: 600;
      }
    }
    .register-form {
      color: #840024;
      p.message.text-white {
        width: 50%;
        ${SIZES.mobile} {
          width: 75%;
        }
      }
      .form-group {
        margin: 12px auto;
        width: 50%;
        border: 1px solid #cb0a3e;
        background: #f8f4ff;
        text-align: center;
        border-radius: 30px;
        ${SIZES.mobile} {
          width: 75%;
        }
        label {
          margin: 0 auto;
          font-weight: 500;
          font-style: italic;
          padding: 4px auto;
          padding-bottom: 0;
          font-size: 12px;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #2f2651;
          letter-spacing: 1px;
          width: 100%;
          background: transparent;
          border: none;
          padding: 4px 24px;
          outline: none;
          font-size: 12px;
        }
        .react-tel-input {
          color: #2f2651;
          input[type='tel'] {
            padding-left: 56px;
            height: 25px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
    }
  }

  a,
  button.submit-btn {
    background: #cb0a3e;
    width: 50%;
    margin: 0 auto;
    color: #f6f6f9;
    font-weight: 500;
    font-style: italic;
    border: solid 2px white;
    border-radius: 30px;
    display: flex;
    padding: 4px 24px;
    justify-content: space-between;
    font-size: 0.8em;
    outline: none;
    box-shadow: none;
    ${SIZES.mobile} {
      width: 75%;
    }
    &:disabled {
      cursor: not-allowed;
    }
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function ResetPasswordPage() {
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(
      `${config.apiUrl}/api/v1/create_password`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${queryString.parse(location.search).token}`,
        },
      }
    )
      .then(() => {
        setLoading(false)
        toast('Your password is successfully created!')
        history.push(routes.login)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    password.length &&
    passwordConfirmation.length &&
    password === passwordConfirmation
  )

  return (
    <Page>
      <Row noGutters>
        <Col md={{ span: 6, offset: 3 }}>
          <header className="">
            <h3>Welcome</h3>
            <p>To A New Era With AMD</p>
          </header>
          <Form onSubmit={handleSubmit}>
            <Row className="register-form py-5 px-0 px-md-3" noGutters>
              <Form.Group
                as={Col}
                md={12}
                className="align-self-end px-5 mb-0 pl-md-2"
              >
                <label>Password</label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={12}
                className="align-self-end px-5 mb-0 pl-md-2"
              >
                <label>Password Confirmation</label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </Form.Group>
              <Col md={12} className="align-self-end px-5 pb-4 mt-4">
                {hasEmptyField && (
                  <p className="text-white text-right">
                    <em>
                      <small>Please make sure both password matches</small>
                    </em>
                  </p>
                )}
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="default"
                  disabled={hasEmptyField || loading}
                  onSubmit={handleSubmit}
                >
                  <div>{loading ? 'Loading...' : 'CREATE PASSWORD'}</div>
                  <div className="arrow">⇀</div>
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Page>
  )
}
