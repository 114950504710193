import React, { useState } from 'react'
import styled from 'styled-components'

import { COLORS, SIZES } from '../styles/theme'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, registerUser } from '../ducks/auth'
import { Redirect, Link } from 'react-router-dom'
import ForgetPassword from '../components/ForgetPassword'
import config from '../config'
import routes from '../routes'
import { Button } from 'react-bootstrap'
import shapes from '../images/registration/Shapes.png'

const LoginBox = styled.section`
  position: fixed;
  bottom: 0;
  right: 5vw;
  padding: 6vw;
  padding-bottom: 0;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
  color: #F6F6F9;
  font-style: italic;

  .action-text {
    font-weight: bold;
    color: ${COLORS.primary};
    cursor: pointer;

    &:hover {
      color: ${COLORS.primaryTrans};
    }
  }

  form {
    background-image: url(${shapes});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 30px 10px;
    margin: auto;
    margin-top: 30px;
    max-width: 400px;

    input {
      letter-spacing: 1px;
      width: 80%;
      background: #F8F4FF;
      border: 1px solid #ACAAB7;
      padding: 15px 32px;
      border-radius: 30px;
      margin-top: 20px;
      color: #2F2651;
      &::placeholder {
        color: ${COLORS.lightGray1};
      }
    }

    .forget-pass {
      width: 80%;
      color: ${COLORS.primaryTrans};
      margin-top: 10px;
      font-size: 0.9em;
    }

    .login-btn {
      background: ${COLORS.primary};
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 1.2em;
      color: #F6F6F9;
      font-weight: 500;
      padding: 15px 32px;
      border-radius: 56px;
      font-style: italic ;
      
      &:disabled {
        cursor: not-allowed;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.5em;
        align-self: flex-end;
      }
    }
  }

  ${SIZES.mobile} {
    position: static;
    padding: 20% 10px;
    min-height: 100vh;
    max-height: auto;
    background-color: ${COLORS.primary};
    background-image: linear-gradient(
      rgba(203, 10, 62, 1),
      rgba(203, 10, 62, 1)
    ),
    url(${config.assets.countdown});
    background-blend-mode: multiply;
    background-position: center;
  }
`

export default function LoginPage() {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }

  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <LoginBox>
        <h3 className="font-weight-bold">Welcome</h3>
        <p>Enter your email and password to login</p>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={onEmailInput}
          />
          <input
            type="password"
            value={password}
            placeholder="Password"
            onChange={onPasswordInput}
          />
          <Button 
            disabled={!(email.length && password.length) || isFetching}
            type="submit"
            className="btn login-btn"
            variant="default" 
            onSubmit={handleLogin}
          >
            {isFetching ? 'LOGGING IN...' : 'Submit'}
            <div className="arrow">⇀</div>
          </Button>
          <ForgetPassword>
            <span className="action-text">Forgot Password</span>
          </ForgetPassword>

          {/*** Redirect to Sign Up Page ***/}
          <p className="text-sencondary mt-3">
            Don't have account?{' '}
            <Link to={routes.preRegister} className="action-text">
              Sign Up
            </Link>
          </p>
        </form>
      </LoginBox>
    )
  }
}
