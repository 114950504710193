import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import routes from '../routes'
import { SIZES } from '../styles/theme'

import { Row, Col, Button } from 'react-bootstrap'
import logo from '../images/AMDlogo.png'
import ryzen from '../images/registration/phonemock.png'

const Page = styled.div`
  height: 100vh;
  color: #FCFCFC;
  padding: 5em;

  ${SIZES.mobile} {
    font-size: 80%;
    padding: 2.5em;
    padding-bottom: 0;
  }
  .content {

    .col-md-4 {
      text-align: center;
      font-style: italic;
      height: calc( 100vh - 8em);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      .title {
        font-size: 1.75em;
        font-weight: 600;
      }
      p {
        font-size: 1.25em;
      }
      img {
        display: block;
        margin: 0 auto;
      }
      ${SIZES.mobile} {
        .title {
          font-size: 2em;
        }
        p {
          font-size: 1.5em;
        }
      }
    }
  }
  a,
  button {
    background: #CB0A3E;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    font-size: 1.2em;
    color: #F6F6F9;
    font-weight: 500;
    padding: 15px 35px;
    border-radius: 56px;
    font-style: italic ;

    &:hover {
      color: #F6F6F9;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function WelcomePage() {
  return (
    <Page>
      <Row className="content" noGutters>
        <Col md={{ span: 4, offset: 4 }}>
          <img src={logo} width="40%" alt="AMD"/>
          <div>
            <h3 className="title my-0 mt-md-5">Welcome</h3>
            <p className="text-white">Enter your email to login</p>
          </div>
          <img src={ryzen} width="350px" alt="ryzen"/>
          <Button variant="default" as={Link} to={routes.login}>
            Login
            <div className="arrow">⇀</div>
          </Button>
        </Col>
      </Row>
    </Page>
  )
}
