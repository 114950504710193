import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import WelcomePage from '../pages/WelcomePage'
import LoginPage from '../pages/LoginPage'
import config from '../config'
import { SIZES } from '../styles/theme'
import routes from '../routes'

const Page = styled.div`
  background: url(${config.assets.entrance.landscape}) no-repeat top center fixed;
  ${SIZES.mobile} {
    background: url(${config.assets.entrance.portrait}) no-repeat center 10% fixed;
    background-size: auto;
  }
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <Switch>
        <Route exact path={routes.root} component={WelcomePage} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Page>
  )
}
