const TOGGLE_MUTE = 'TOGGLE_MUTE'

// Q&A Form Modal in auditorium
const TOGGLE_QUESTION_FORM = 'TOGGLE_QUESTION_FORM'

// Programme Schedule Modal
const SHOW_SCHEDULE = 'SHOW_SCHEDULE'
const HIDE_SCHEDULE = 'HIDE_SCHEDULE'

// Singapore promo Modal
const SHOW_SG = 'SHOW_SG'
const HIDE_SG = 'HIDE_SG'

// Malaysia promo Modal
const SHOW_MY = 'SHOW_MY'
const HIDE_MY = 'HIDE_MY'

const initialState = {
  showForm: false,
  muted: false,
  scheduleModal: false,
  malaysiaModal: false,
  singaporeModal: false,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MUTE:
      return {
        ...state,
        muted: !state.muted,
      }

    case TOGGLE_QUESTION_FORM:
      return {
        ...state,
        showForm: !state.showForm,
      }

    case SHOW_SCHEDULE:
      return {
        ...state,
        scheduleModal: true,
      }

    case HIDE_SCHEDULE:
      return {
        ...state,
        scheduleModal: false,
      }

    case SHOW_MY:
      return {
        ...state,
        malaysiaModal: true,
      }

    case HIDE_MY:
      return {
        ...state,
        malaysiaModal: false,
      }

    case SHOW_SG:
      return {
        ...state,
        singaporeModal: true,
      }

    case HIDE_SG:
      return {
        ...state,
        singaporeModal: false,
      }

    default:
      return state
  }
}

export function toggleMute() {
  return { type: TOGGLE_MUTE }
}

export function toggleShowForm() {
  return { type: TOGGLE_QUESTION_FORM }
}

export function showSchedule() {
  return { type: SHOW_SCHEDULE }
}

export function showMy() {
  return { type: SHOW_MY }
}

export function showSg() {
  return { type: SHOW_SG }
}
